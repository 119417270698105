import React, { useState, useEffect, useCallback } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

export default function Permissoes() {
  const [permissoes, setPermissoes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [permissaoToDelete, setPermissaoToDelete] = useState(null);
  const [permissaoToEdit, setPermissaoToEdit] = useState(null);
  const [novaPermissao, setNovaPermissao] = useState({ nome: '', descricao: '' });
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'descricao',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'acoes',
      text: 'Ações',
      formatter: (cell, row) => (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-primary mr-2"
            onClick={() => handleEdit(row)}
          >
            <i className="fas fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => {
              setPermissaoToDelete(row);
              setShowModalDelete(true);
            }}
          >
            <i className="fas fa-trash" />
          </button>
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: permissoes.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const buscarPermissoes = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/permissoes/tipos');
      setPermissoes(response.data);
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
      toast.error('Erro ao carregar permissões', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    buscarPermissoes();
  }, [buscarPermissoes]);

  const handleEdit = (permissao) => {
    setPermissaoToEdit(permissao);
    setNovaPermissao({ nome: permissao.nome, descricao: permissao.descricao });
    setShowModalEdit(true);
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    
    if (!novaPermissao.nome || !novaPermissao.descricao) {
      toast.error('Preencha todos os campos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    try {
      await api.put(`/api/permissoes/tipos/${permissaoToEdit.id}`, novaPermissao);
      toast.success('Permissão atualizada com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setShowModalEdit(false);
      setNovaPermissao({ nome: '', descricao: '' });
      setPermissaoToEdit(null);
      buscarPermissoes();
    } catch (error) {
      if (error.response?.data?.error === 'Esta permissão já existe') {
        toast.error('Esta permissão já existe', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      } else {
        console.error('Erro ao atualizar permissão:', error);
        toast.error('Erro ao atualizar permissão', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!novaPermissao.nome || !novaPermissao.descricao) {
      toast.error('Preencha todos os campos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    try {
      await api.post('/api/permissoes/tipos', novaPermissao);
      toast.success('Permissão criada com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setShowModal(false);
      setNovaPermissao({ nome: '', descricao: '' });
      buscarPermissoes();
    } catch (error) {
      if (error.response?.data?.error === 'Esta permissão já existe') {
        toast.error('Esta permissão já existe', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      } else {
        console.error('Erro ao criar permissão:', error);
        toast.error('Erro ao criar permissão', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/api/permissoes/tipos/${permissaoToDelete.id}`);
      toast.success('Permissão excluída com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setShowModalDelete(false);
      setPermissaoToDelete(null);
      buscarPermissoes();
    } catch (error) {
      console.error('Erro ao excluir permissão:', error);
      toast.error('Erro ao excluir permissão', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Gerenciar Permissões</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <div className="d-flex flex-row-reverse mb-4">
          <button 
            type="button"
            className="btn-primario"
            onClick={() => setShowModal(true)}
          >
            Nova Permissão
          </button>
        </div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={permissoes}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  loading ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3" />
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Nova Permissão</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={novaPermissao.nome}
                onChange={(e) => setNovaPermissao({ ...novaPermissao, nome: e.target.value })}
                placeholder="Digite o nome da permissão"
                className="input-theme"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={novaPermissao.descricao}
                onChange={(e) => setNovaPermissao({ ...novaPermissao, descricao: e.target.value })}
                placeholder="Digite a descrição da permissão"
                className="input-theme"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit" className="btn-primario">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Permissão</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitEdit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                value={novaPermissao.nome}
                onChange={(e) => setNovaPermissao({ ...novaPermissao, nome: e.target.value })}
                placeholder="Digite o nome da permissão"
                className="input-theme"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={novaPermissao.descricao}
                onChange={(e) => setNovaPermissao({ ...novaPermissao, descricao: e.target.value })}
                placeholder="Digite a descrição da permissão"
                className="input-theme"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModalEdit(false)}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit" className="btn-primario">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Excluir Permissão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja excluir a permissão "{permissaoToDelete?.nome}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalDelete(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
} 