import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarUsuario } from '../../../store/modules/autenticacao/actions';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import validarCpf from '../../../utils/validarCpf';
import { toast } from 'react-toastify';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector(
    (state) => state.autenticacao.autenticandoPainel
  );

  useEffect(() => {
    document.title = 'American Burrs - Login';
  }, []);

  const solicitarAutenticacao = (e) => {
    e.preventDefault();
    const cpf = e.target.cpf.value.replace(/[^\d]/g, '');
    const senha = e.target.senha.value;

    if (!validarCpf(cpf)) {
      toast.error('CPF inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    dispatch(autenticarUsuario(cpf, senha));
  };

  return (
    <div className="d-flex flex-column justify-content-center bg-black bg-full">
      <Container>
        <Row className="flex-column align-items-center justify-content-center">
          <img
            className="w-100 form-login-logo"
            src="/imagens/logo-american-points.png"
            alt="Logo American Burrs"
          ></img>
        </Row>
        <Row className="justify-content-center p-5 bg-black">
          <form className="form-login" onSubmit={solicitarAutenticacao}>
            <h5>FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>
            <label htmlFor="cpf">CPF</label>
            <InputMask
              name="cpf"
              id="cpf"
              type="text"
              placeholder="CPF"
              mask="999.999.999-99"
            />
            <label htmlFor="senha">Senha</label>
            <input name="senha" id="senha" type="password" placeholder="Senha" />
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                ENTRAR
              </button>
            )}
            <Link
              className="mb-3 mt-3 text-center link"
              to="/painel/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>
          </form>
        </Row>
      </Container>
    </div>
  );
}
