import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Footer from '../../../components/Loja/Footer';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import validarCpf from '../../../utils/validarCpf';
import { toast } from 'react-toastify';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    document.title = 'American Burrs - Login';

    const checkIfMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const solicitarAutenticacao = (e) => {
    e.preventDefault();
    const cpf = e.target.cpf.value.replace(/[^\d]/g, '');
    const senha = e.target.senha.value;

    if (!validarCpf(cpf)) {
      toast.error('CPF inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    dispatch(autenticarParceiro(cpf, senha));
  };

  return (
    <div style={{ backgroundColor: '#fff', overflowX: 'hidden' }}>
      <div className="banner-container" style={{ width: '100vw', overflow: 'hidden', marginLeft: 'calc(-50vw + 50%)' }}>
        <img
          style={{ width: '100%', maxWidth: 'none' }}
          src={isMobile ? "imagens/banner-mobile.png" : "imagens/banner-login.png"}
          alt="American Points"
        />
      </div>
      <Container>
        <Row className="justify-content-center mt-5 mb-5">
          <form
            onSubmit={solicitarAutenticacao}
            className="form-login form-login-loja"
          >
            <h5>FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>
            <label htmlFor="cpf">CPF</label>
            <InputMask
              name="cpf"
              id="cpf"
              type="text"
              placeholder="CPF"
              mask="999.999.999-99"
            />
            <label htmlFor="senha">Senha</label>
            <input name="senha" id="senha" type="password" placeholder="Senha" />

            <button disabled={enviando} className="btn-primario" type="submit">
              {enviando ? 'VALIDANDO...' : 'ENTRAR'}
            </button>
            <Link
              className="mb-3 mt-3 text-center link-clean"
              to="/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>
          </form>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
