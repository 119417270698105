import React, { useEffect, useState, useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const { SearchBar } = Search;

export default function Usuarios() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalTelas, setShowModalTelas] = useState(false);
  const [showModalPermissoes, setShowModalPermissoes] = useState(false);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [revendasPermitidas, setRevendasPermitidas] = useState([]);
  const [redesPermitidas, setRedesPermitidas] = useState([]);
  const [revendasDisponiveis, setRevendasDisponiveis] = useState([]);
  const [redesDisponiveis, setRedesDisponiveis] = useState([]);
  const [novaPermissao, setNovaPermissao] = useState('');
  const [telaSelecionada, setTelaSelecionada] = useState('');
  const [nivelPermissao, setNivelPermissao] = useState('');
  const [permissoes, setPermissoes] = useState([]);

  const formatarData = (celula, valor) => {
    if (!celula) return '-';
    return moment(celula).format('DD/MM/YYYY HH:mm:ss');
  };

  const formatarPermissao = (celula, valor) => {
    const permissao = permissoes.find(p => p.id === valor.permissao);
    return permissao ? `${permissao.nome} (${permissao.id})` : valor.permissao;
  };

  const columns = [
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      sort: true,
      formatter: (celula, valor) => {
        const cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'permissao',
      text: 'Tipo de Usuário',
      sort: true,
      formatter: formatarPermissao,
    },
    {
      dataField: 'criadoEm',
      text: 'Data de Cadastro',
      sort: true,
      formatter: formatarData,
    },
    {
      dataField: 'ultimoAcessoEm',
      text: 'Último Acesso',
      sort: true,
      formatter: formatarData,
    },
    {
      dataField: 'acoes',
      text: 'Ações',
      formatter: (celula, valor) => {
        return (
          <div>
            {Number(valor.permissao) === 5 && (
              <button
                type="button"
                className="btn-primario-slim mr-2"
                onClick={() => {
                  setUsuarioSelecionado(valor);
                  setShowModalPermissoes(true);
                  carregarPermissoesUsuario(valor);
                }}
              >
                Gerenciar Permissões
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'nome',
      order: 'asc',
    },
  ];

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const buscarUsuarios = useCallback(async () => {
    setBuscando(true);
    const resultado = await api.get('/api/usuarios');
    if (resultado) {
      setData(resultado.data.usuarios);
    }
    setBuscando(false);
  }, []);

  const buscarPermissoes = useCallback(async () => {
    try {
      const response = await api.get('/api/permissoes/tipos');
      setPermissoes(response.data);
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
      toast.error('Erro ao carregar permissões', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  }, []);

  useEffect(() => {
    buscarUsuarios();
    buscarPermissoes();
  }, [buscarUsuarios, buscarPermissoes]);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const atualizarPermissoes = async () => {
    if (!novaPermissao) {
      toast.error('Selecione uma permissão para atualizar', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    try {
      setBuscando(true);
      await api.put('/api/usuarios/permissoes', {
        cpfs: selected,
        permissao: Number.parseInt(novaPermissao)
      });
      
      await buscarUsuarios();
      setSelected([]);
      setNovaPermissao('');
      setShowModal(false);
      toast.success('Permissões atualizadas com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } catch (error) {
      console.error('Erro ao atualizar permissões:', error);
      toast.error('Erro ao atualizar permissões. Tente novamente.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } finally {
      setBuscando(false);
    }
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelected([...selected, row.cpf]);
      } else {
        setSelected(selected.filter(cpf => cpf !== row.cpf));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelected(rows.map(row => row.cpf));
      } else {
        setSelected([]);
      }
    }
  };

  const adicionarPermissaoTela = async () => {
    if (!telaSelecionada || !nivelPermissao) {
      toast.error('Preencha todos os campos', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    try {
      await api.post('/api/permissoes', {
        tela: telaSelecionada,
        nivelPermissao: Number.parseInt(nivelPermissao)
      });
      
      setTelaSelecionada('');
      setNivelPermissao('');
      toast.success('Permissão adicionada com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } catch (error) {
      if (error.response?.data?.error === 'Esta permissão já existe') {
        toast.error('Esta permissão já existe', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        console.error('Erro ao adicionar permissão:', error);
        toast.error('Erro ao adicionar permissão. Tente novamente.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  const carregarPermissoesUsuario = async (usuario) => {
    try {
      const revendasResponse = await api.get('/api/revendas?ativo=1');
      if (revendasResponse.data.revendas) {
        setRevendasDisponiveis(revendasResponse.data.revendas);
      }

      const redesResponse = await api.get('/api/redes?ativo=1');
      if (redesResponse.data.redes) {
        setRedesDisponiveis(redesResponse.data.redes);
      }

      setRevendasPermitidas(usuario.idRevendasPermitidas ? usuario.idRevendasPermitidas.split(',') : []);
      setRedesPermitidas(usuario.idRedesPermitidas ? usuario.idRedesPermitidas.split(',') : []);
    } catch (error) {
      console.error('Erro ao carregar permissões:', error);
      toast.error('Erro ao carregar permissões do usuário', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleSalvarPermissoes = async () => {
    try {
      await api.put(`/api/usuarios/${usuarioSelecionado.id}/permissoes`, {
        idRevendasPermitidas: revendasPermitidas.join(','),
        idRedesPermitidas: redesPermitidas.join(','),
      });

      toast.success('Permissões atualizadas com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setShowModalPermissoes(false);
      buscarUsuarios();
    } catch (error) {
      console.error('Erro ao atualizar permissões:', error);
      toast.error('Erro ao atualizar permissões', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleRevendaChange = (e) => {
    const revendaId = e.target.value;
    if (e.target.checked) {
      setRevendasPermitidas([...revendasPermitidas, revendaId]);
    } else {
      setRevendasPermitidas(revendasPermitidas.filter(id => id !== revendaId));
    }
  };

  const handleRedeChange = (e) => {
    const redeId = e.target.value;
    if (e.target.checked) {
      setRedesPermitidas([...redesPermitidas, redeId]);
    } else {
      setRedesPermitidas(redesPermitidas.filter(id => id !== redeId));
    }
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Usuários do Sistema</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="cpf"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
                <button 
                  type="button"
                  className="btn-primario-slim mr-2"
                  onClick={() => setShowModal(true)}
                  disabled={selected.length === 0}
                >
                  Atualizar Permissões ({selected.length})
                </button>
                {usuario.permissao === 2 && (
                  <button 
                    type="button"
                    className="btn-primario-slim mr-2"
                    onClick={() => setShowModalTelas(true)}
                  >
                    Gerenciar Permissões de Telas
                  </button>
                )}
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3" />
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                selectRow={selectRow}
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Atualizar Permissões</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Usuários selecionados: {selected.length}</p>
            <Form.Group>
              <Form.Label>Nova Permissão</Form.Label>
              <Form.Control
                as="select"
                value={novaPermissao} 
                onChange={(e) => setNovaPermissao(e.target.value)}
                className="input-theme"
              >
                <option value="">Selecione uma permissão</option>
                {permissoes.map(permissao => (
                  <option key={permissao.id} value={permissao.id}>
                    {permissao.nome} ({permissao.id})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-secondary" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
            <Button 
              className="btn-primary"
              onClick={atualizarPermissoes}
              disabled={!novaPermissao}
            >
              Atualizar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalTelas} onHide={() => setShowModalTelas(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Gerenciar Permissões de Telas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Tela</Form.Label>
              <Form.Control
                type="text"
                value={telaSelecionada}
                onChange={(e) => setTelaSelecionada(e.target.value)}
                placeholder="/exemplo/rota"
                className="input-theme"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nível de Permissão</Form.Label>
              <Form.Control
                as="select"
                value={nivelPermissao}
                onChange={(e) => setNivelPermissao(e.target.value)}
                className="input-theme"
              >
                <option value="">Selecione um nível</option>
                {permissoes.map(permissao => (
                  <option key={permissao.id} value={permissao.id}>
                    {permissao.nome} ({permissao.id})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-secondary" onClick={() => setShowModalTelas(false)}>
              Cancelar
            </Button>
            <Button 
              className="btn-primary"
              onClick={adicionarPermissaoTela}
              disabled={!telaSelecionada || !nivelPermissao}
            >
              Adicionar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalPermissoes} onHide={() => setShowModalPermissoes(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Gerenciar Permissões - {usuarioSelecionado?.nome}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <h5>Revendas Permitidas</h5>
              <div className="row">
                {revendasDisponiveis.map(revenda => (
                  <div key={revenda.id} className="col-md-4 mb-2">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`revenda-${revenda.id}`}
                        value={revenda.id}
                        checked={revendasPermitidas.includes(revenda.id.toString())}
                        onChange={handleRevendaChange}
                      />
                      <label className="form-check-label" htmlFor={`revenda-${revenda.id}`}>
                        {revenda.nomeFantasia}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h5>Redes Permitidas</h5>
              <div className="row">
                {redesDisponiveis.map(rede => (
                  <div key={rede.id} className="col-md-4 mb-2">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`rede-${rede.id}`}
                        value={rede.id}
                        checked={redesPermitidas.includes(rede.id.toString())}
                        onChange={handleRedeChange}
                      />
                      <label className="form-check-label" htmlFor={`rede-${rede.id}`}>
                        {rede.nome}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-secondary" onClick={() => setShowModalPermissoes(false)}>
              Cancelar
            </Button>
            <Button className="btn-primary" onClick={handleSalvarPermissoes}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
} 