import api from '../../../services/API';

const menuItens = [
  {
    destino: '/dashboard',
    titulo: 'Dashboard',
    icone: 'fa fa-fw fa-home',
  },
  {
    destino: '/mensuracao',
    titulo: 'Mensuração',
    icone: 'fa fa-fw fa-chart-line',
  },
  {
    destino: '/historico',
    titulo: 'Histórico',
    icone: 'fa fa-fw fa-chart-area',
  },
  {
    destino: '/taxa-sucesso',
    titulo: 'Taxa de Sucesso',
    icone: 'fa fa-fw fa-check',
  },
  {
    destino: '/produtos',
    titulo: 'Produtos',
    icone: 'fa fa-fw fa-box',
  },
  {
    destino: '/premios',
    titulo: 'Prêmios',
    icone: 'fa fa-fw fa-gifts',
  },
  {
    destino: '/pontuacoes',
    titulo: 'Pontuações',
    icone: 'fa fa-fw fa-user-plus',
    subItens: [
      {
        destino: '/pontuado',
        titulo: 'Pontuado',
      },
      {
        destino: '/encerrado',
        titulo: 'Encerrado',
      },
    ],
  },
  {
    destino: '/antifraude',
    titulo: 'Antifraude',
    icone: 'fa fa-fw fa-file-contract',
  },
  {
    destino: '/compras-pontuacao',
    titulo: 'Compras x Pontuação',
    icone: 'fa fa-fw fa-shopping-bag',
  },
  {
    destino: '/produtos-vendidos',
    titulo: 'Produtos Vendidos',
    icone: 'fa fa-fw fa-truck-loading',
  },
  {
    destino: '/resgates',
    titulo: 'Resgates',
    icone: 'fa fa-fw fa-gift',
  },
  {
    destino: '/fotos-videos',
    titulo: 'Fotos e Vídeos',
    icone: 'fa fa-fw fa-camera',
  },
  {
    destino: '/cartoes',
    titulo: 'Cartões',
    icone: 'fa fa-fw fa-credit-card',
  },
  {
    destino: '/vendedores',
    titulo: 'Vendedores',
    icone: 'fa fa-fw fa-users',
  },
  {
    destino: '/Lojas',
    titulo: 'Lojas',
    icone: 'fa fa-fw fa-store',
  },
  {
    destino: '/redes',
    titulo: 'Redes',
    icone: 'fa fa-fw fa-industry',
  },
  {
    destino: '/logs',
    titulo: 'Logs',
    icone: 'fa fa-fw fa-file-alt',
  },
  {
    destino: '/usuarios',
    titulo: 'Usuários',
    icone: 'fa fa-fw fa-user',
  },
  {
    destino: '/configuracao-whats',
    titulo: 'Configuração Whats',
    icone: 'fa fa-fw fa-comment-dots',
  },
  {
    destino: '/cadastrar',
    titulo: 'Cadastrar',
    icone: 'fa fa-fw fa-edit',
    subItens: [
      {
        destino: '/administrador',
        titulo: 'Administrador',
      },
      {
        destino: '/rede',
        titulo: 'Rede',
      },
      {
        destino: '/revenda',
        titulo: 'Loja',
      },
      {
        destino: '/cartao',
        titulo: 'Cartão',
      },
      {
        destino: '/premio',
        titulo: 'Prêmio',
      },
      {
        destino: '/produto',
        titulo: 'Produto',
      },
      {
        destino: '/parceiro',
        titulo: 'Vendedor Loja',
      },
      {
        destino: '/permissoes',
        titulo: 'Permissões',
      },
    ],
  },
  {
    destino: '/processar',
    titulo: 'Processar',
    icone: 'fa fa-fw fa-upload',
    subItens: [
      {
        destino: '/produtos',
        titulo: 'Produtos',
      },
      {
        destino: '/vendas',
        titulo: 'Vendas',
      },
      {
        destino: '/historico',
        titulo: 'Histórico',
      },
      {
        destino: '/pontos',
        titulo: 'Pontos',
      },
      {
        destino: '/pontuacoes',
        titulo: 'Pontuações Pendentes',
      },
      {
        destino: '/saldo-cartao',
        titulo: 'Saldo Cartão',
      },
      {
        destino: '/relatorios',
        titulo: 'Relatórios',
      },
      {
        destino: '/correcao-pontuacao',
        titulo: 'Correção de Pontuação',
      },
      {
        destino: '/foto-video',
        titulo: 'Foto/Vídeo',
      },
    ],
  },
];

async function carregarPermissoes() {
  try {
    const response = await api.get('/api/permissoes');
    const { permissoes } = response.data;
    
    const itensComPermissoes = menuItens.map(item => {
      const permissoesItem = permissoes.find(p => p.tela === item.destino)?.permissoes || [];
      
      return {
        ...item,
        permissao: permissoesItem,
        subItens: item.subItens?.map(subItem => {
          const permissoesSubItem = permissoes.find(p => p.tela === item.destino + subItem.destino)?.permissoes || [];
          
          return {
            ...subItem,
            permissao: permissoesSubItem
          };
        })
      };
    });
    
    return itensComPermissoes;
  } catch (error) {
    console.error('carregarPermissoes - Erro ao carregar permissões:', error);
    return menuItens;
  }
}

export { carregarPermissoes };
export default menuItens;
