import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import Toggle from 'react-bootstrap-toggle';
import { toast } from 'react-toastify';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'valorPontos',
    order: 'desc',
  },
];

const ExportCSV = (props) => {
  return (
    <div>
      <CSVLink
        data={props.data}
        separator={';'}
        filename={`Produtos${moment().format('YYYYMMDDHHmmss')}.csv`}
        headers={[
          { label: 'Código', key: 'codigo' },
          { label: 'Produto', key: 'nome' },
          { label: 'Valor Unitário', key: 'valorUnitario' },
          { label: 'Pontos', key: 'valorPontos' },
          { label: 'Pontos Exibição', key: 'valorPontosExibicao' }
        ]}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

export default function ProdutosCriteria() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [id, setId] = useState('');
  const [produto, setProduto] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [dataFormated, setDataFormated] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [limite, setLimite] = useState(10);
  const [total, setTotal] = useState(0);
  const [busca, setBusca] = useState('');
  const [ordenacao, setOrdenacao] = useState(defaultSorted[0]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    buscarProdutos();
  }, [pagina, limite, busca, ordenacao]);

  const buscarProdutos = async () => {
    setBuscando(true);
    try {
      const resultado = await api.get(
        `/api/produtos?&pagina=${pagina}&limite=${limite}&buscar=${busca}&ordenacao=${JSON.stringify(
          { campo: ordenacao.dataField, direcao: ordenacao.order }
        )}`
      );
      if (resultado) {
        setData(resultado.data.rows);
        setTotal(resultado.data.count);
      }
    } catch (error) {
      toast.error('Erro ao buscar produtos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
    setBuscando(false);
  };

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
    switch (type) {
      case 'pagination':
        setPagina(page);
        setLimite(sizePerPage);
        break;
      case 'search':
        setBusca(searchText || '');
        setPagina(1);
        break;
      case 'sort':
        setOrdenacao({ dataField: sortField, order: sortOrder });
        setPagina(1);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      dataField: 'codigo',
      text: 'Código',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'nome',
      text: 'Produto',
      sort: true,
    },
    {
      dataField: 'valorUnitario',
      text: 'Valor Unitário',
      formatter: (celula, valor) => {
        return Number(valor.valorUnitario)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      hidden: true,
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorPontos',
      text: 'Pontos',
      formatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorPontosExibicao',
      text: 'Pontos Exibição',
      formatter: (celula, valor) => {
        return Number(valor.valorPontosExibicao).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => {
        return (
          <Toggle
            onClick={(state) => updateStatus(state, valor.id)}
            on={<span>Sim</span>}
            off={<span>Não</span>}
            size="sm"
            offstyle="danger"
            active={valor.ativo === 1 ? true : false}
          />
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.nome);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  async function updateStatus(state, id) {
    const resultado = await api.put(`/api/produtos/${id}`, {
      ativo: state ? 1 : 0,
    });

    if (resultado) {
      toast.success('Produto atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    setData(
      data.map((d) => {
        if (d.id === id) {
          d.ativo = state ? 1 : 0;
        }
        return d;
      })
    );
  }

  const abrirModalExcluir = (id, produto) => {
    setProduto(produto);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setProduto('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  const atualizarProduto = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          codigo: d.codigo,
          nome: d.nome,
          valorUnitario: Number(d.valorUnitario)
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            .replace(/\u00a0/g, ' '),
          valorPontos: Number(d.valorPontos).toLocaleString('pt-BR'),
          valorPontosExibicao: Number(d.valorPontosExibicao).toLocaleString('pt-BR')
        }))
      );
    }
  }, [data]);

  const options = {
    page: pagina,
    sizePerPage: limite,
    totalSize: total,
    showTotal: true,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const downloadTemplate = async () => {
    try {
      const response = await api.get('/api/produtos/template', {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template_produtos.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error('Erro ao baixar template', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      toast.error('Por favor, selecione um arquivo CSV', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setUploading(true);
    try {
      const response = await api.post('/api/produtos/importar', formData);
      
      if (response.data.erros && response.data.erros.length > 0) {
        toast.warning(`Importação concluída com ${response.data.erros.length} erros. Verifique o console para mais detalhes.`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
        console.log('Erros na importação:', response.data.erros);
      } else {
        toast.success('Importação concluída com sucesso!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
      }
      
      buscarProdutos();
    } catch (error) {
      toast.error('Erro ao importar arquivo', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } finally {
      setUploading(false);
      event.target.value = null; // Reset input
    }
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Produtos American Burrs</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="codigo"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
                <button
                  className="btn-primario-slim mr-2"
                  onClick={downloadTemplate}
                  disabled={uploading}
                >
                  <i className="fa fa-download mr-1"></i>
                  Template
                </button>
                <label className="btn-primario-slim mr-2 mb-0 d-flex align-items-center justify-content-center" style={{ cursor: 'pointer', minWidth: '100px' }}>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                    disabled={uploading}
                  />
                  <i className="fa fa-upload mr-1"></i>
                  {uploading ? 'Importando...' : 'Importar'}
                </label>
              </div>
              <BootstrapTable
                remote
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                keyField="codigo"
                pagination={paginationFactory(options)}
                onTableChange={handleTableChange}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        produto={produto}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarProduto={atualizarProduto}
      />
    </Container>
  );
}
